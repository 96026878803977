<template>
  <div class="p-modal">
    <div class="p-container">
      <a @click="$emit('close')">close</a>
      <h5>set competition options</h5>
      <!-- Workflow modal going through steps to create a new competition and invite friends -->
      <form @submit.prevent>
        Title: <input v-model.trim="title" />
        Description: <input v-model.trim="description" />
        Type: <input v-model.trim="type" />
        Duration: <input v-model.trim="duration" />
        Scope: <input v-model.trim="scope" />
        Win structure: <input v-model.trim="structure" />
        Max players (optional): <input v-model.trim="playerCap" />
        Buy in (optional): <input v-model.trim="stake" />
        Invite players: <input v-model.trim="invitations" />

        <button @click="createNewComp()" :disabled="title == ''" class="button">Confirm</button>
      </form>
    </div>
  </div>
</template>

<script>
// import { commentsCollection, postsCollection, auth } from '@/firebase'
import { mapState } from 'vuex'

export default {
  // props: ['post'],
  data() {
    return {
      title: '',
      description: '',
      type: '',
      duration: '',
      scope: '',
      structure: '',
      playerCap: '',
      stake: '',
      invitations: ''
    }
  },
  computed: {
    ...mapState(['userProfile', 'competitions'])
  },
  methods: {
    async createNewComp() {
      console.log("Creating new competition ...")

      this.$store.dispatch('createNewComp', {
        title: this.title,
        description: this.description,
        type: this.type,
        duration: this.duration,
        scope: this.scope,
        structure: this.structure,
        playerCap: this.playerCap,
        stake: this.stake,
        invitations: this.invitations
      })

      // close modal
      this.$emit('close')
    }
  }
}
</script>
