<template>
  <div id="dashboard">
    <transition name="fade">
      <CreateNewCompModal v-if="showCreateNewCompModal" @close="toggleCreateNewCompModal()"></CreateNewCompModal>
    </transition>
    <section>
      <div class="col2">
        <!-- <h3>[ Public | Invitational | Private ]   [ My Comps ]</h3> -->
        <div v-if="competitions.length">
          <div v-for="comp in competitions" :key="comp.id" class="listing">
            <div class="grid-container">
              <div class="grid-child">
                <h5>{{ comp.title }}</h5>
                <ul>
                  <li>{{ comp.type }}</li>
                  <li>{{ comp.description }}</li>
                  <li>{{ comp.scope }}</li>
                </ul>
              </div>
              <div class="grid-child">
                <button class="button">Join</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="no-results">No active competitions found :(</p>
        </div>
      </div>

      <div class="col1">
        <div class="profile">
          <div class="create-post">
            <h5>Create</h5>
            <p>Want to start your own competition?</p>
            <p>Click here to set one up!</p>
            <form @submit.prevent>
              <!-- <textarea v-model.trim="post.content"></textarea> -->
              <button @click="toggleCreateNewCompModal()" class="button">Create</button>
            </form>
          </div>
        </div>
      </div>
    </section>

    <!-- <transition name="fade">
      <div v-if="showCreateNewCompModal" class="p-modal">
        <div class="p-container">
          <a @click="closeCreateNewCompModal()" class="close">close</a>
          <div class="post"> 
            <h5>title</h5>
            <span>description</span>
          </div>
        </div>
      </div>
    </transition> -->

  </div>
</template>

<script>
// import { listingsCollection } from '@/firebase'
import { mapState } from 'vuex'
import { competitionsCollection } from '@/firebase'
import CreateNewCompModal from '@/components/CreateNewCompModal'

export default {
  components: {
    CreateNewCompModal,

  },
  data() {
    return {
      // listing: {
      // // URL: '',
      // // Address: '',
      // // AuctionDate: '',
      // // userListingBid: 0,
      // },
      showCreateNewCompModal: false,
      // showAddListingModal: false,
      // showPlaceBidModal: false,
      // showReportResultModal: false,
      // showFullListingModal: false,
      // selectedListing: {},
      // fullListing: {},
      // search: '',
    }
  },
  computed: {
    ...mapState(['userProfile', 'competitions']),

    // filteredAndSortedListings: function () {
    //   let result = this.listings
    //   // Apply status filter first
    //   if (this.statusToggle) {
    //     result = result.filter(listing => listing.status == this.statusToggle)
    //   }
    //   // Apply search bar text filter
    //   if (this.search) {
    //     result = result.filter(listing => listing.Address.toLowerCase().includes(this.search.toLowerCase()))
    //   }
    //   // Apply other sort / filter options
    //   // TODO
    //   return result
    // }
  },
  methods: {

    // toggleSearchUsersModal() {
    //   this.showSearchUsersModal = !this.showSearchUsersModal
    //   console.log("SearchUsersModal toggled to " + this.showSearchUsersModal)
    // },
    // closeSearchUsersModal() {
    //   this.showSearchUsersModal = false
    // },
    toggleCreateNewCompModal() {
      this.showCreateNewCompModal = !this.showCreateNewCompModal
      console.log("CreateNewCompModal toggled to " + this.showCreateNewCompModal)
    },
    closeCreateNewCompModal() {
      this.showCreateNewCompModal = false
    }
    // toggleReportResultModal(listing) {
    //   this.showReportResultModal = !this.showReportResultModal
    //   console.log("ReportResultModal toggled to " + this.showReportResultModal)
    //   if (this.showReportResultModal) {
    //     this.selectedListing = listing
    //     console.log("Selected listing is now: " + this.selectedListing.Address)
    //   } else {
    //     this.selectedListing = {}
    //     console.log("Selected listing has been cleared")
    //   }
    // },
    // togglePlaceBidModal(listing) {
    //   this.showPlaceBidModal = !this.showPlaceBidModal
    //   console.log("PlaceBidModal toggled to " + this.showPlaceBidModal)
    //   if (this.showPlaceBidModal) {
    //     this.selectedListing = listing
    //     console.log("Selected listing is now: " + this.selectedListing.Address)
    //   } else {
    //     this.selectedListing = {}
    //     console.log("Selected listing has been cleared")
    //   }
    // },
    
    // likePost(id, likesCount) {
    //   this.$store.dispatch('likePost', { id, likesCount })
    // },
    // async viewListing(listing) {
    //   const docs = await listingsCollection.where('listingId', '==', listing.id).get()

    //   // docs.forEach(doc => {
    //   //   let comment = doc.data()
    //   //   comment.id = doc.id
    //   //   this.postComments.push(comment)
    //   // })

    //   this.fullListing = listing
    //   this.showListingModal = true
    // },

  },
  filters: {
    trimLength(val) {
      if (val.length < 200) { return val }
      return `${val.substring(0, 200)}...`
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
